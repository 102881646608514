import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HtmlContentLoader } from '@powdr/components';
import { FetchStatus } from '@powdr/constants';
import { getAlerts } from '@powdr/stores';
import { formatDateFromEpoch } from '@powdr/utils';

import {
  AlertIcon,
  Message,
  StyledTvAlerts, Timestamp, TimestampDate, TimestampIconWrapper, TimestampTime,
} from './styles';

export const TvAlerts = ({
  className,
  displayTime,
  skip,
}) => {
  const dispatch = useDispatch();
  const useAlertData = useSelector((state) => state.alerts);
  const { data: alerts, status } = useAlertData;
  const [currentPage, setCurrentPage] = useState(0);
  const [alertsReady, setAlertsReady] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const alertRotationRef = useRef();

  const getTimestampDate = (t) => {
    const dateObj = formatDateFromEpoch(t.updated);
    return `${dateObj.month.long} ${dateObj.day.num}, ${dateObj.year}`;
  };

  const getTimestampTime = (t) => {
    const dateObj = formatDateFromEpoch(t.updated);
    return `${dateObj.time.hours}:${dateObj.time.minutes} ${dateObj.time.ampm.toUpperCase()} Update`;
  };

  useEffect(() => {
    dispatch(getAlerts());
  }, [dispatch]);

  useEffect(() => {
    if (status === FetchStatus.SUCCESS && (!alerts || alerts?.length === 0)) {
      skip();
    } else {
      setAlertsReady(true);
      setNumPages(alerts.length);
    }
  }, [alerts, status, currentPage, skip]);

  useEffect(() => {
    if (numPages !== null) {
      const perPageDisplayTime = (displayTime * 1000) / numPages;
      alertRotationRef.current = setTimeout(() => {
        setCurrentPage((currentPage === numPages - 1) ? 0 : currentPage + 1);
      }, perPageDisplayTime);
    }

    return () => {
      clearTimeout(alertRotationRef.current);
    };
  }, [currentPage, numPages, displayTime]);

  if (!alertsReady) return null;

  return (
    <StyledTvAlerts className={className}>
      {/* <button
        onClick={() => setCurrentPage((currentPage === numPages - 1) ? 0 : currentPage + 1)}
      >
        Move Page
      </button> */}
      <TimestampIconWrapper>
        <AlertIcon $alertType={alerts[currentPage].type} name="ui-error" />
        <Timestamp>
          <TimestampTime>{getTimestampTime(alerts[currentPage])}</TimestampTime>
          <TimestampDate>{getTimestampDate(alerts[currentPage])}</TimestampDate>
        </Timestamp>
      </TimestampIconWrapper>
      <Message>
        <HtmlContentLoader html={alerts[currentPage].message} allowRerender />
      </Message>
    </StyledTvAlerts>
  );
};

TvAlerts.propTypes = {
  className: PropTypes.string,
  displayTime: PropTypes.number,
  skip: PropTypes.func.isRequired,
};

TvAlerts.defaultProps = {
  className: '',
  displayTime: null,
};
