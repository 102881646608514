// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React, { useContext, useRef, useEffect } from 'react';

import { HeaderLogo, Icon } from '@powdr/components';
import { AbsolutePositions, AlignmentShorthand, Components } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useIsInView } from '@powdr/hooks';

import {
  ChildrenWrapper,
  ComponentDrawerHeader, DrawerCloseButton, InterceptPoint, LogoWrapper, StyledComponentDrawer,
} from './styles';

export const ComponentDrawer = ({
  isOpen,
  slideInFrom,
  closeDrawerFunc,
  closeButtonPosition,
  closeButtonLabel,
  componentTheme,
  isShowLogoHeader,
  isForceShadow,
  className,
  children,
}) => {
  const { isMobile } = useContext(AppContext);
  const interceptRef = useRef();
  const drawerRef = useRef();
  const isShowHeaderShadow = useIsInView(interceptRef);

  useEffect(() => {
    if (drawerRef.current && !isOpen) {
      drawerRef.current.setAttribute('inert', '');
      drawerRef.current.blur();
    } else {
      drawerRef.current.removeAttribute('inert');
      drawerRef.current.focus();
    }
  }, [isOpen]);

  return (
    <StyledComponentDrawer
      ref={drawerRef}
      tabIndex={0}
      aria-label="Beginning of drawer, use Tab to move to other components"
      className={className}
      $componentTheme={componentTheme}
      $isOpen={isOpen || false}
      $slideInFrom={slideInFrom || AbsolutePositions.RIGHT}
    >
      {(isShowLogoHeader) ? (
        <ComponentDrawerHeader $isShowShadow={!isShowHeaderShadow || isForceShadow}>
          <LogoWrapper>
            <HeaderLogo isFromDrawer />
          </LogoWrapper>
          {/* Close Button */}
          <DrawerCloseButton
            aria-label={closeButtonLabel || 'Close Drawer'}
            onClick={closeDrawerFunc}
            $position={closeButtonPosition || AlignmentShorthand.TOP_RIGHT}
            $componentTheme={componentTheme}
          >
            <Icon className="drawer-close-button" name="ui-close" width={(isMobile) ? 25 : 40} />
          </DrawerCloseButton>
        </ComponentDrawerHeader>
      ) : (
        <DrawerCloseButton
          aria-label={closeButtonLabel || 'Close Drawer'}
          onClick={closeDrawerFunc}
          $position={closeButtonPosition || AlignmentShorthand.TOP_RIGHT}
          $componentTheme={componentTheme}
          $isShowShadow={!isShowHeaderShadow || isForceShadow}
        >
          <Icon className="drawer-close-button" name="ui-close" width={(isMobile) ? 25 : 40} />
        </DrawerCloseButton>
      )}
      <ChildrenWrapper $isShowLogoHeader={isShowLogoHeader}>
        <InterceptPoint ref={interceptRef} />
        {children}
      </ChildrenWrapper>
    </StyledComponentDrawer>
  );
};

ComponentDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  slideInFrom: PropTypes.oneOf(Object.values(AbsolutePositions)).isRequired,
  closeButtonPosition: PropTypes.oneOf(Object.values(AlignmentShorthand)),
  closeButtonLabel: PropTypes.string,
  componentTheme: PropTypes.string,
  isShowLogoHeader: PropTypes.bool,
  isForceShadow: PropTypes.bool,
  closeDrawerFunc: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

ComponentDrawer.defaultProps = {
  isForceShadow: false,
  isShowLogoHeader: false,
  componentTheme: Components.COMPONENT_DRAWER,
  closeButtonPosition: AlignmentShorthand.TOP_RIGHT,
  closeButtonLabel: null,
};
